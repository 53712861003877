import React from "react";
import styles from "./Spinner.module.css";

/**
 * Renders a spinner component.
 * @returns {JSX.Element} The spinner component.
 */
const Spinner = () => {
  return (
    <div className={styles.spinner}>
      <div className={styles.doubleBounce1}></div>
      <div className={styles.doubleBounce2}></div>
    </div>
  );
};

export default Spinner;
