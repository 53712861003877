import { createContext, useContext } from "react";

/**
 * @typedef {import('path/to/AppStateContext').AppStateContext} AppStateContext
 */
export const AppStateContext = createContext();

/**
 * Custom hook that provides access to the app state context.
 * @returns {AppState} The app state context.
 */
export function useAppState() {
  return useContext(AppStateContext);
}
