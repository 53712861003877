import React from "react";
import styles from "./SuccessMessage.module.css";

/**
 * SuccessMessage component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.message - The success message to display.
 * @returns {JSX.Element} The rendered SuccessMessage component.
 */
const SuccessMessage = ({ message }) => {
  return <div className={styles.success}>{message}</div>;
};

export default SuccessMessage;
