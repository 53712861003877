import React from "react";
import { Helmet } from "react-helmet";
import styles from "./AboutUs.module.css";

const AboutUs = ({ webPilotAPI }) => {
  return (
    <>
      <Helmet>
        <title>About Us - Empowering Your Tech Choices</title>
        <meta
          name="description"
          content="We are a dedicated team of tech enthusiasts, passionate about making cutting-edge technology accessible to all. Learn more about us."
        />
        <meta
          name="keywords"
          content="tech enthusiasts, gadget reviews, personalized recommendations, tech shopping, technology, affordable tech"
        />
        <meta
          property="og:title"
          content="About Us - Empowering Your Tech Choices"
        />
        <meta
          property="og:description"
          content="We are a dedicated team of tech enthusiasts, passionate about making cutting-edge technology accessible to all. Learn more about us."
        />
        <meta
          property="og:keywords"
          content="tech enthusiasts, gadget reviews, personalized recommendations, tech shopping, technology, affordable tech"
        />
      </Helmet>

      <section className={styles.aboutUsSection}>
        <div className={styles.container}>
          <h2 className={styles.title}>About Us</h2>
          <p className={styles.description}>
          We are a team of tech enthusiasts passionate about innovation, dedicated to making advanced technology accessible for everyone. Our mission is to provide you with the insights you need to make confident, informed decisions when choosing the latest gadgets.
          </p>
          <div className={styles.highlightsContainer}>
            <div className={styles.highlight}>
              <h3 className={styles.highlightTitle}>Tech Enthusiasts</h3>
              <p className={styles.highlightDescription}>
                We love exploring new devices, analyzing their features, and
                sharing our insights with you.
              </p>
            </div>
            <div className={styles.highlight}>
              <h3 className={styles.highlightTitle}>Fast & Reliable</h3>
              <p className={styles.highlightDescription}>
                Our platform ensures quick and accurate recommendations
                delivered straight to your inbox.
              </p>
            </div>
            <div className={styles.highlight}>
              <h3 className={styles.highlightTitle}>Affordable</h3>
              <p className={styles.highlightDescription}>
                For just $3, get a detailed, personalized recommendation crafted
                specifically to suit your needs.{" "}
                {webPilotAPI && (
                  <span>You can also choose the extended version for $5.</span>
                )}
              </p>
            </div>
          </div>
          <p className={styles.footerText}>
            Together, we’re here to simplify your tech shopping experience. Your
            satisfaction drives our passion for innovation!
          </p>
          {/* <div className="tax-b">
            *The above price does not include tax. Tax will be automatically
            added to the base price during the transaction. Transactions are
            handled by Stripe.
          </div> */}
        </div>
      </section>
    </>
  );
};

export default AboutUs;
