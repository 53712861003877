import React, { useReducer } from "react";
import { AppStateContext } from "./AppStateContext";
import { initialState } from "./initialState";
import appReducer from "./appReducer";

/**
 * AppStateProvider component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
