import React from "react";
import styles from "./ErrorMessage.module.css";

/**
 * Renders an error message based on the provided status code.
 *
 * @param {Object} props - The component props.
 * @param {number} props.statusCode - The status code to determine the error message.
 * @returns {JSX.Element} The error message component.
 */
const ErrorMessage = ({ statusCode }) => {
  let message = "An unknown error occurred.";

  switch (statusCode) {
    case 400:
      message = "Bad Request: The server could not understand the request.";
      break;
    case 403:
      message =
        "Forbidden: You do not have permission to access this resource.";
      break;
    case 404:
      message = "Sorry, we couldn't find any items to display.";
      break;
    case 500:
      message = "Internal Server Error: Something went wrong on the server.";
      break;
    default:
      message = `Error: Something went wrong. Status code: ${statusCode}`;
      break;
  }

  return <div className={styles.error}>{message}</div>;
};

export default ErrorMessage;
