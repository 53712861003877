import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import * as Sentry from "@sentry/react";
import Spinner from "../../common/Other/Spinner";
import ErrorMessage from "../../common/Message/ErrorMessage";
import Footer from "../Footer/Footer";
import styles from "./Article.module.css";

const Article = () => {
  const { id } = useParams();

  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/blog/article/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (response.status === 200 && response.data && response.data.article) {
          setArticle(response.data.article);
        } else {
          setError("Article not found");
        }
      } catch (error) {
        if (error.response) {
          setError(error.response.status);
        } else {
          setError("An error occurred. Please try again later.");
        }
        Sentry.captureException(error, {
          extra: {
            message: "Error while getting single article",
            component: "Article",
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage statusCode={error} />;
  }

  if (
    !article ||
    !article.title ||
    !article.shortDescription ||
    !article.descriptionA
  ) {
    return (
      <div className={styles.error}>
        <p>Article not found or could not be loaded correctly.</p>
      </div>
    );
  }

  const validateValue = (value, fallback = "Data not available") => {
    return value && value.trim() !== "" ? value : fallback;
  };

  return (
    <>
      <Helmet>
        <title>{validateValue(article.metaTitle)}</title>
        <meta name="description" content={validateValue(article.metaDesc)} />
        <meta name="keywords" content={article.metaKey} />
      </Helmet>
      <section className={styles.articleDetail}>
        <div className={styles.container}>
          <h1 className={styles.title}>
            {validateValue(article.title, "Untitled Article")}
          </h1>
          {article.image ? (
            <img
              src={validateValue(article.image)}
              alt={validateValue(article.title, "Article Image")}
              className={styles.articleImage}
            />
          ) : (
            <div className={styles.noImage}>No image available</div>
          )}
          <div className={styles.content}>
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{
                __html: validateValue(
                  article.descriptionA,
                  "<p>No content available</p>"
                ),
              }}
            />
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{
                __html: validateValue(
                  article.descriptionB,
                  "<p>No content available</p>"
                ),
              }}
            />
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{
                __html: validateValue(
                  article.descriptionC,
                  "<p>No content available</p>"
                ),
              }}
            />
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{
                __html: validateValue(
                  article.descriptionD,
                  "<p>No content available</p>"
                ),
              }}
            />
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{
                __html: validateValue(
                  article.descriptionE,
                  "<p>No content available</p>"
                ),
              }}
            />

            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{
                __html: validateValue(
                  article.summary,
                  "<p>No content available</p>"
                ),
              }}
            />
          </div>

          <div className={styles.content}>
            Keywords:{" "}
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{
                __html: validateValue(
                  article.tags,
                  "<p>No content available</p>"
                ),
              }}
            />
          </div>

          <div className={styles.content}>
            Author:{" "}
            <div
              className={styles.paragraph}
              dangerouslySetInnerHTML={{
                __html: validateValue(
                  article.author,
                  "<p>No content available</p>"
                ),
              }}
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Article;
