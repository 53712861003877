/**
 * Reducer function for managing the application state.
 *
 * @param {Object} state - The current state of the application.
 * @param {Object} action - The action object that describes the state change.
 * @returns {Object} - The new state after applying the action.
 */
const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      if (!action.payload) {
        console.error("Payload is undefined");
        return state;
      }
      return {
        ...state,
        csrfToken: action.payload.csrfToken || state.csrfToken,
      };
    default:
      return state;
  }
};

export default appReducer;
